const eventTrackingTypes = {
  supplies: {
    name: 'supplies',
    category: 'Purchase Type',
    action: 'Supply Purchase',
    label: 'num_supplies',
  },
  seedlings: {
    name: 'seedlings',
    category: 'Purchase Type',
    action: 'Seedling Purchase',
    label: 'num_seedlings',
  },
  bundles: {
    name: 'bundles',
    category: 'Purchase Type',
    action: 'Bundle Purchase',
    label: 'num_bundles',
  },
  giftcards: {
    name: 'giftcards',
    category: 'Purchase Type',
    action: 'Gift Card Purchase',
    label: 'num_giftcards',
  },
};

export default eventTrackingTypes;
